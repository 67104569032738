import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const About = () => (
  <>
    <Header />
    <motion.div
      className="pt-20 p-6 bg-gradient-to-r from-green-100 to-green-300 min-h-screen flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Helmet>
        <title>アバウト - DaDa中国の毛織物工場"</title>
        <meta name="description" content="中国の毛織物工場" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://dadaclothes.com/",
              "@type": "WebPage",
              "name": "About Us",
              "description": "中国の毛織物工場.",
              "url": "https://dadaclothes.com/"
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-5xl font-extrabold mb-4 text-center">アバウト</h1>
      <p className="text-2xl text-center max-w-2xl mb-8">
        {/* We are committed to providing the best services. Learn more about our journey and values. */}
        当社は最高のサービスを提供することに尽力しています。当社の取り組みと価値観について詳しくご覧ください。
      </p>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex justify-center">
          <img src="/images/image3.jpg" alt="Image3" className="w-full h-auto max-w-sm shadow-lg rounded"/>
        </div>
        <div className="flex justify-center">
          <img src="/images/image5.jpg" alt="Image5" className="w-full h-auto max-w-sm shadow-lg rounded"/>
        </div>
        <div className="flex justify-center">
          <img src="/images/image4.jpg" alt="Image4" className="w-full h-auto max-w-sm shadow-lg rounded"/>
        </div>
      </div>
    </motion.div>
  </>
);

export default About;
