import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const Information = () => (
  <>
    <Header />
    <motion.div
      className="pt-20 p-6 bg-gradient-to-r from-purple-100 to-purple-300 min-h-screen flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Helmet>
        <title>情報 - dadamanufactureSite</title>
        <meta name="description" content="外貿に関する常識を紹介します。" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://dadaclothes.com/",
              "@type": "WebPage",
              "name": "情報",
              "description": "外貿に関する常識を紹介します。",
              "url": "https://dadaclothes.com/"
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-5xl font-extrabold mb-4 text-center">情報</h1>
      <p className="text-2xl text-center max-w-2xl mb-8">
        外貿に関する基本的な知識を紹介します。これには、輸出入の手続き、貿易条約、関税、物流などが含まれます。外貿は国際的なビジネス活動の一環として、重要な役割を果たしています。
      </p>
      <div className="bg-white p-6 rounded shadow-md max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold mb-4">服装外貿進出口関税に関する情報</h2>
        <p className="mb-4">
          服装業界における外貿進出口は、国際市場でのビジネスを展開するために非常に重要です。このプロセスには、各国の関税制度に基づく税金や手数料が関連してきます。以下は、服装外貿進出口関税に関する基本的な情報です。
        </p>
        <h3 className="text-2xl font-semibold mb-2">1. 関税とは</h3>
        <p className="mb-4">
          関税は、外国から輸入される商品に対して課される税金です。これには、服装製品も含まれます。関税の目的は、国内産業を保護し、国の財政収入を増やすことです。
        </p>
        <h3 className="text-2xl font-semibold mb-2">2. 服装輸出の関税</h3>
        <p className="mb-4">
          日本から服装を輸出する際には、輸出関税は通常課されませんが、輸出する国によっては輸入関税が適用されることがあります。例えば、欧州連合（EU）やアメリカ合衆国などでは、特定の服装製品に対して関税が課されることがあります。
        </p>
        <h3 className="text-2xl font-semibold mb-2">3. 服装輸入の関税</h3>
        <p className="mb-4">
          外国から日本に服装を輸入する際には、輸入関税が適用されます。関税率は商品分類によって異なり、一般的には3%から12%の範囲で設定されています。また、特定の自由貿易協定（FTA）に基づく優遇措置が適用される場合、関税が減免されることもあります。
        </p>
        <h3 className="text-2xl font-semibold mb-2">4. 関税の計算方法</h3>
        <p className="mb-4">
          関税は、商品の輸入価格（CIF価格：Cost, Insurance, and Freight）に基づいて計算されます。CIF価格には、商品価格、運送費用、および保険料が含まれます。この価格に関税率を掛け合わせることで、支払うべき関税額が算出されます。
        </p>
        <h3 className="text-2xl font-semibold mb-2">5. 関税と通関手続き</h3>
        <p className="mb-4">
          輸入関税を支払うためには、輸入者は税関で通関手続きを行う必要があります。これには、インボイス（商業送り状）、輸入申告書、およびその他の必要書類の提出が含まれます。通関手続きが完了すると、商品は正式に輸入され、国内市場で販売することができます。
        </p>
        <h3 className="text-2xl font-semibold mb-2">6. 特別な考慮事項</h3>
        <p>
          特定の国や地域との間で締結された自由貿易協定（FTA）や経済連携協定（EPA）に基づき、関税が減免される場合があります。これにより、特定の条件を満たす輸入品は、通常の関税率よりも低い関税率が適用されることがあります。
        </p>
      </div>
    </motion.div>
  </>
);




export default Information;
