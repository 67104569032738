import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <nav className="bg-blue-600 p-4 shadow-lg fixed top-0 left-0 w-full z-50">
    <ul className="flex space-x-8 justify-center text-lg font-semibold">
      <li><Link className="text-white hover:text-gray-200 transition-colors" to="/">ホーム</Link></li>
      <li><Link className="text-white hover:text-gray-200 transition-colors" to="/about">アバウト</Link></li>
      <li><Link className="text-white hover:text-gray-200 transition-colors" to="/services">サービス</Link></li>
      <li><Link className="text-white hover:text-gray-200 transition-colors" to="/information">情報</Link></li>
      <li><Link className="text-white hover:text-gray-200 transition-colors" to="/contact">コンタクト</Link></li>
    </ul>
  </nav>
);

export default Header;
