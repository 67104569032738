import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const Service = () => (
  <>
    <Header />
    <motion.div
      className="pt-20 p-6 bg-gradient-to-r from-yellow-100 to-yellow-300 min-h-screen flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Helmet>
        <title>サービス - My Independent Site</title>
        <meta name="description" content="サービスについての情報。" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "サービス",
              "description": "中国製造業者として、最良の毛料工場を見つけるお手伝いをします。専門家による最高のコンサルティングとサービスを提供します。",
              "url": "http://www.myindependentsite.com/services"
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-5xl font-extrabold mb-4 text-center">サービス</h1>
      <p className="text-2xl text-center max-w-2xl mb-8">
       
私たちは中国の衣料品製造業界から来ており、最高のウール工場を見つけるお手伝いをします。最高のコンサルティングとサービスを提供する非常に専門的なスタッフがいます。
      </p>
    </motion.div>
  </>
);

export default Service;
