import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const Contact = () => (
  <>
    <Header />
    <motion.div
      className="pt-20 p-6 bg-gradient-to-r from-pink-100 to-pink-300 min-h-screen flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Helmet>
        <title>コンタクト - DaDa中国の毛織物工場"</title>
        <meta name="description" content="お問い合わせはこちらからどうぞ。" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://dadaclothes.com/",
              "@type": "中国の毛織物工場",
              "name": "コンタクト",
              "description": "お問い合わせはこちらからどうぞ。",
              "url": "https://dadaclothes.com/"
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-5xl font-extrabold mb-4 text-center">コンタクト</h1>
      <p className="text-2xl text-center max-w-2xl mb-8">
        お問い合わせはこちらからどうぞ。<br/>
        メールアドレス: <a href="mailto:18616203252@163.com" className="text-blue-600 underline">18616203252@163.com</a>
      </p>
    </motion.div>
  </>
);

export default Contact;
