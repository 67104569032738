import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const Home = () => (
  <>
    <Header />
    <motion.div
      className="pt-20 p-6 bg-gradient-to-r from-blue-100 to-blue-300 min-h-screen flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <Helmet>
        <title>ホーム - DaDa中国の毛織物工場"</title>
        <meta name="description" content="中国の毛織物工場" />
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "中国の毛織物工場"",
              "url": "https://dadaclothes.com/",
              "potentialAction": {
                "@type": "SearchAction",
                "target": "https://dadaclothes.com/",
                "query-input": "required name=search_term_string"
              }
            }
          `}
        </script>
      </Helmet>
      <h1 className="text-5xl font-extrabold mb-4 text-center">中国縫製工場の外国貿易情報</h1>
      <p className="text-2xl text-center mb-8">素晴らしいコンテンツを発見し、滞在をお楽しみください!</p>
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="flex justify-center">
          <img src="/images/image1.jpg" alt="Image 1" className="w-full h-auto max-w-sm shadow-lg rounded"/>
        </div>
        <div className="flex justify-center">
          <img src="/images/image2.jpg" alt="Image 2" className="w-full h-auto max-w-sm shadow-lg rounded"/>
        </div>
      </div>
    </motion.div>
  </>
);

export default Home;
